var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "mt-12",
    attrs: {
      "fluid": ""
    }
  }, [_c('TreeViewWithPreview', {
    staticClass: "justify-center d-flex",
    attrs: {
      "tree-view-items": _vm.state.corporateUnits,
      "is-loading": _vm.state.isLoadingOrgUnits,
      "tree-view-children-property": "organizationalUnits",
      "preview-item": _vm.state.previewItem
    },
    on: {
      "click:label": function clickLabel($event) {
        _vm.state.previewItem = $event;
      },
      "click:plusIconMember": function clickPlusIconMember($event) {
        _vm.state.isAddOrgUnitManagementMemberDialogOpen = true;
      },
      "click:plusIconLabel": function clickPlusIconLabel($event) {
        return _vm.listeners.onClickAddOrgUnit($event);
      },
      "click:editIconMember": function clickEditIconMember($event) {
        return _vm.listeners.onClickEditIconMember($event);
      },
      "click:delete": function clickDelete($event) {
        _vm.state.isDeleteDialogOpen = true;
      },
      "click:edit": function clickEdit($event) {
        return _vm.listeners.onClickEdit();
      },
      "refetch": function refetch($event) {
        return _vm.functions.init();
      }
    },
    scopedSlots: _vm._u([{
      key: "tree-view-header",
      fn: function fn() {
        return [_vm.functions.hasSufficientRights(_vm.constants.Rights.CORPORATE_UNIT_CREATE) && _vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('v-btn', {
          staticClass: "rounded-lg",
          attrs: {
            "small": "",
            "outlined": "",
            "elevation": "0",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.listeners.onClickAddCorporateUnit();
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('baseData.corporateStructure.actions.addCorporateUnit')) + " ")], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "tree-plus-icon",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.ORGANIZATIONAL_UNIT_CREATE) || !_vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('div') : _vm._e()];
      },
      proxy: true
    }, {
      key: "table-teamMember",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.ORGANIZATIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_READ) ? _c('div') : _vm._e()];
      },
      proxy: true
    }, {
      key: "edit-teamMember",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.ORGANIZATIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_UPDATE) || !_vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('div') : _vm._e()];
      },
      proxy: true
    }, {
      key: "delete-teamMember",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.ORGANIZATIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_DELETE) ? _c('div') : _vm._e()];
      },
      proxy: true
    }, {
      key: "add-teamMember",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.ORGANIZATIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_CREATE) || !_vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('div') : _vm._e()];
      },
      proxy: true
    }, {
      key: "delete-unit",
      fn: function fn() {
        return [!_vm.functions.hasSufficientDeleteRight(_vm.state.previewItem.unitType) ? _c('div') : _vm._e()];
      },
      proxy: true
    }, {
      key: "edit-unit",
      fn: function fn() {
        return [!_vm.functions.hasSufficientEditRight(_vm.state.previewItem.unitType) || !_vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('div') : _vm._e()];
      },
      proxy: true
    }])
  }, [_vm.state.isAddEditCorporateUnitDialogOpen ? _c('AddEditCorporateUnitDialog', {
    attrs: {
      "corporate-unit-to-edit": _vm.functions.cloneDeep(_vm.state.itemToEdit)
    },
    on: {
      "added-edited": function addedEdited($event) {
        return _vm.listeners.onAddedOrEditedUnit();
      }
    },
    model: {
      value: _vm.state.isAddEditCorporateUnitDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditCorporateUnitDialogOpen", $$v);
      },
      expression: "state.isAddEditCorporateUnitDialogOpen"
    }
  }) : _vm._e(), _vm.state.isAddEditOrgUnitDialogOpen ? _c('AddEditOrgUnitDialog', {
    attrs: {
      "org-unit-to-edit": _vm.functions.cloneDeep(_vm.state.itemToEdit),
      "corporate-unit-id": _vm.state.activeCorporateUnitId,
      "org-unit-parent-id": _vm.state.orgUnitParentId,
      "tree-view-items": _vm.state.corporateUnits
    },
    on: {
      "added-edited": function addedEdited($event) {
        return _vm.listeners.onAddedOrEditedUnit($event);
      },
      "inputOrgUnitParentId": function inputOrgUnitParentId($event) {
        _vm.state.orgUnitParentId = $event;
      }
    },
    model: {
      value: _vm.state.isAddEditOrgUnitDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditOrgUnitDialogOpen", $$v);
      },
      expression: "state.isAddEditOrgUnitDialogOpen"
    }
  }) : _vm._e(), _vm.state.isAddOrgUnitManagementMemberDialogOpen ? _c('AddEditManagementTeamMemberDialog', {
    attrs: {
      "member-to-edit": _vm.state.memberToEdit,
      "entity-id": _vm.state.previewItem.id,
      "entity-type": _vm.constants.UNIT_TYPE.ORG_UNIT
    },
    on: {
      "add": function add($event) {
        return _vm.listeners.onAddOrgUnitManagementTeamMember($event);
      },
      "edit": function edit($event) {
        return _vm.listeners.onEditOrgUnitManagementTeamMember($event);
      },
      "close": function close($event) {
        return _vm.listeners.onCloseOrgUnitManagementTeamMemberDialog();
      }
    },
    model: {
      value: _vm.state.isAddOrgUnitManagementMemberDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddOrgUnitManagementMemberDialogOpen", $$v);
      },
      expression: "state.isAddOrgUnitManagementMemberDialogOpen"
    }
  }) : _vm._e(), _vm.state.isDeleteDialogOpen ? _c('CommonDeleteDialog', {
    on: {
      "cancel": function cancel($event) {
        _vm.state.isDeleteDialogOpen = false;
      },
      "delete": function _delete($event) {
        return _vm.listeners.onDelete();
      }
    },
    model: {
      value: _vm.state.isDeleteDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeleteDialogOpen", $$v);
      },
      expression: "state.isDeleteDialogOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('baseData.corporateStructure.dialog.delete.text', {
    type: _vm.$t("baseData.corporateStructure.".concat(_vm.state.previewItem.unitType)),
    name: "".concat(_vm.state.previewItem.name)
  })) + " ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }