
import { defineComponent, reactive, ref } from '@vue/composition-api'
import cloneDeep from 'lodash-es/cloneDeep'
import { mdiPlus } from '@mdi/js'

import TreeViewWithPreview from '@/views/baseData/components/TreeViewWithPreview.vue'

import { useNotify } from '@/store'

import { useDeleteCorporateUnit, useGetCorporateUnits } from '@/api/corporateUnit'
import { useDeleteOrgUnit, useGetOrgUnits } from '@/api/orgUnit'
import {
  useCreateOrgUnitManagementTeamMember,
  useUpdateOrgUnitManagementTeamMember,
} from '@/api/orgUnitManagementTeamMember'

import { handleError } from '@/utils/handleError'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { CorporateUnitOutput, CorporateUnitId, CorporateUnitTreeStructure } from '@/api/types/corporateUnit'
import { OrgUnit, OrgUnitId, OrgUnitTreeStructure } from '@/api/types/orgUnit'
import { NewOrgUnitManagementTeamMember, OrgUnitManagementTeamMember } from '@/api/types/orgUnitManagementTeamMember'
import { UNIT_TYPE } from '@/api/types/misc'
import { Rights } from '@/api/types/right'
import { isOfType } from '@/utils/types/isOfType'

export default defineComponent({
  name: 'CorporateStructure',
  components: {
    TreeViewWithPreview,
    AddEditCorporateUnitDialog: () =>
      import('@/views/baseData/views/corporateStructure/components/AddEditCorporateUnitDialog.vue'),
    AddEditOrgUnitDialog: () =>
      import('@/views/baseData/views/corporateStructure/components/AddEditOrgUnitDialog.vue'),
    AddEditManagementTeamMemberDialog: () =>
      import('@/views/baseData/components/AddEditManagementTeamMemberDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
  },
  setup: (_, { root }) => {
    const { addNotification } = useNotify()

    const { exec: getCorporateUnits, data: corporateUnits } = useGetCorporateUnits<CorporateUnitTreeStructure>()

    const isAddEditCorporateUnitDialogOpen = ref(false)

    const activeCorporateUnitId = ref<CorporateUnitId | null>(null)

    function onClickAddCorporateUnit(): void {
      isAddEditCorporateUnitDialogOpen.value = true
    }

    const previewItem = ref<CorporateUnitTreeStructure | CorporateUnitOutput | OrgUnitTreeStructure | OrgUnit | null>(
      null
    )

    const itemToEdit = ref<CorporateUnitOutput | OrgUnit | null>(null)

    const { exec: getOrgUnits, data: orgUnits, isLoading: isLoadingOrgUnits } = useGetOrgUnits<OrgUnitTreeStructure>()

    const isAddEditOrgUnitDialogOpen = ref(false)

    const orgUnitParentId = ref<OrgUnitId | null>(null)

    function onClickAddOrgUnit(item: OrgUnitTreeStructure | CorporateUnitTreeStructure): void {
      if (item.unitType === UNIT_TYPE.CORP_UNIT) {
        activeCorporateUnitId.value = item.id
        itemToEdit.value = null
      } else {
        activeCorporateUnitId.value = item.corporateUnit.id
        orgUnitParentId.value = item.id
        itemToEdit.value = null
      }

      isAddEditOrgUnitDialogOpen.value = true
    }

    const isAddOrgUnitManagementMemberDialogOpen = ref(false)

    const { createOrgUnitManagementTeamMember } = useCreateOrgUnitManagementTeamMember()

    async function onAddOrgUnitManagementTeamMember(teamMember: NewOrgUnitManagementTeamMember): Promise<void> {
      try {
        await createOrgUnitManagementTeamMember(teamMember)

        init()

        onCloseOrgUnitManagementTeamMemberDialog()

        addNotification({
          text: root.$t('misc.added') as string,
          type: 'success',
          timeout: 3000,
        })
      } catch (error: unknown) {
        handleError(error)
      }
    }

    const isMemberEditMode = ref(false)

    const memberToEdit = ref<OrgUnitManagementTeamMember | null>(null)

    const { updateOrgUnitManagementTeamMember } = useUpdateOrgUnitManagementTeamMember()

    function onClickEditIconMember(teamMember: OrgUnitManagementTeamMember): void {
      isAddOrgUnitManagementMemberDialogOpen.value = true

      isMemberEditMode.value = true

      memberToEdit.value = { ...teamMember }
    }

    async function onEditOrgUnitManagementTeamMember(): Promise<void> {
      try {
        if (!memberToEdit.value) return

        Object.entries(memberToEdit.value as OrgUnitManagementTeamMember).forEach(
          ([key, value]) => value && value.id && memberToEdit.value && (memberToEdit.value[key] = value.id)
        )

        await updateOrgUnitManagementTeamMember(memberToEdit.value.id, memberToEdit.value)

        init()

        onCloseOrgUnitManagementTeamMemberDialog()

        addNotification({
          text: root.$t('misc.edited') as string,
          type: 'success',
          timeout: 3000,
        })
      } catch (error: unknown) {
        handleError(error)
      }
    }

    function onCloseOrgUnitManagementTeamMemberDialog(): void {
      isAddOrgUnitManagementMemberDialogOpen.value = false

      isMemberEditMode.value = false

      memberToEdit.value = null
    }

    const isDeleteDialogOpen = ref(false)

    const { deleteCorporateUnit } = useDeleteCorporateUnit()

    const { deleteOrgUnit } = useDeleteOrgUnit()

    async function onDelete(): Promise<void> {
      try {
        if (previewItem.value) {
          switch ((previewItem.value as CorporateUnitTreeStructure | OrgUnitTreeStructure).unitType) {
            case UNIT_TYPE.CORP_UNIT:
              await deleteCorporateUnit(previewItem.value.id)
              break
            case UNIT_TYPE.ORG_UNIT:
              await deleteOrgUnit(previewItem.value.id)
              break
          }
        }

        addNotification({
          text: root.$t('misc.deleted') as string,
          type: 'success',
          timeout: 3000,
        })

        init()
      } catch (error: unknown) {
        handleError(error)
      }

      isDeleteDialogOpen.value = false
    }

    function onClickEdit(): void {
      switch ((previewItem.value as CorporateUnitTreeStructure | OrgUnitTreeStructure).unitType) {
        case UNIT_TYPE.CORP_UNIT:
          isAddEditCorporateUnitDialogOpen.value = true
          itemToEdit.value = previewItem.value
          break
        case UNIT_TYPE.ORG_UNIT:
          isAddEditOrgUnitDialogOpen.value = true
          itemToEdit.value = previewItem.value
          break
      }
    }

    function onAddedOrEditedUnit(): void {
      activeCorporateUnitId.value = null
      orgUnitParentId.value = null
      init()
    }

    function populateOrgUnitsProperty(): void {
      corporateUnits.value.forEach((corporateUnit) => {
        const matchingOrgUnits = orgUnits.value.filter(
          (orgUnit) => orgUnit.corporateUnit.id === corporateUnit.id && !orgUnit.parent
        )

        corporateUnit.organizationalUnits = matchingOrgUnits

        corporateUnit.unitType = UNIT_TYPE.CORP_UNIT
      })

      orgUnits.value.forEach((orgUnit) => {
        const matchingOrgUnits = orgUnits.value.filter(
          (subOrgUnit) => subOrgUnit.parent && orgUnit.id === subOrgUnit.parent.id
        )
        orgUnit.organizationalUnits = matchingOrgUnits

        orgUnit.unitType = UNIT_TYPE.ORG_UNIT
      })
    }

    async function init(): Promise<void> {
      await getCorporateUnits()
      await getOrgUnits()

      populateOrgUnitsProperty()

      if (isOfType<OrgUnit>(previewItem.value, 'corporateUnit')) {
        previewItem.value = orgUnits.value.find((orgUnit) => orgUnit.id === previewItem.value?.id) || null
      } else {
        previewItem.value = corporateUnits.value.find((corpUnit) => corpUnit.id === previewItem.value?.id) || null
      }
    }

    init()

    function hasSufficientEditRight(unitType: UNIT_TYPE): unknown {
      switch (unitType) {
        case UNIT_TYPE.ORG_UNIT:
          return hasSufficientRights(Rights.ORGANIZATIONAL_UNIT_UPDATE)
        case UNIT_TYPE.CORP_UNIT:
          return hasSufficientRights(Rights.CORPORATE_UNIT_UPDATE)
        default:
          return false
      }
    }

    function hasSufficientDeleteRight(unitType: UNIT_TYPE): unknown {
      switch (unitType) {
        case UNIT_TYPE.ORG_UNIT:
          return hasSufficientRights(Rights.ORGANIZATIONAL_UNIT_DELETE)
        case UNIT_TYPE.CORP_UNIT:
          return hasSufficientRights(Rights.CORPORATE_UNIT_DELETE)
        default:
          return false
      }
    }

    return reactive({
      icons: {
        mdiPlus,
      },
      constants: {
        Rights,

        UNIT_TYPE,
      },
      state: {
        corporateUnits,
        isLoadingOrgUnits,
        isAddEditCorporateUnitDialogOpen,

        activeCorporateUnitId,
        previewItem,

        itemToEdit,

        isAddEditOrgUnitDialogOpen,
        orgUnitParentId,

        isAddOrgUnitManagementMemberDialogOpen,

        isMemberEditMode,
        memberToEdit,

        isDeleteDialogOpen,
      },
      listeners: {
        onClickAddCorporateUnit,

        onClickAddOrgUnit,

        onAddOrgUnitManagementTeamMember,

        onClickEditIconMember,
        onEditOrgUnitManagementTeamMember,

        onCloseOrgUnitManagementTeamMemberDialog,

        onDelete,

        onClickEdit,

        onAddedOrEditedUnit,
      },
      functions: {
        cloneDeep,

        getCorporateUnits,

        init,

        hasSufficientRights,
        hasSufficientDeleteRight,
        hasSufficientEditRight,
      },
    })
  },
})
